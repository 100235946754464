
import { ref, defineComponent } from 'vue';
import { useRepositories } from "@/repository/repositories";
import type { ElForm } from 'element-plus';
import { ElMessage } from 'element-plus';
import { validatePassword } from "@/util/validate";
type FormData = {
  password?: string;
  passwordConfirmation?: string;
}
type DataType = {
  formData: FormData;
  resetToken?: string;
  passwordConfirmationErr?: string;
};

export default defineComponent({
  name: "user-reset-password",

  setup() {
    const ruleFormRef = ref<InstanceType<typeof ElForm>>();
    const repos = useRepositories();
    return {
      ruleFormRef,
      repos,
    }
  },

  data(): DataType {
    return {
      formData: {
        password: undefined,
        passwordConfirmation: undefined,
      },
      resetToken: undefined,
      passwordConfirmationErr: undefined,
    }
  },

  computed: {
    validateMessage(): (key: string) => string {
      return (key: string): string => {
        switch (key) {
          case "password": {
            if (this.formData.password === undefined) return '';
            const { message } = validatePassword(this.formData.password);
            return message;
          }
          case "passwordConfirmation": {
            if (this.formData.password !== this.formData.passwordConfirmation) {
              return "パスワードと一致させてください";
            }
            return "";
          }
          default: {
            throw new Error("unexpected validation target");
          }
        }
      };
    },
  },

  methods: {
    modifyFormDataForValidate(): void {
      if (this.formData.passwordConfirmation === undefined) {
        this.formData.passwordConfirmation = "";
      }
      if (this.formData.password === undefined) {
        this.formData.password = "";
      }
    },

    isValidFormData(): boolean {
      const validConfirmation = (this.formData.password !== this.formData.passwordConfirmation);
      if (validConfirmation) return false;
      const res = validatePassword(this.formData.password ?? "");
      if (!res.valid) return false;
      return true;
    },
    
    _setResetToken(): void {
      this.resetToken = this.$route.query.resetToken as string;
    },
    _validateResetToken(): boolean {
      if (this.resetToken === undefined || this.resetToken.length === 0) {
        ElMessage({
          message: '無効なURLです。再度パスワード再設定URLを取得してください。',
          type: "error",
        });
        return false;
      }
      return true;
    },
    async reset(): Promise<void> {
      this.modifyFormDataForValidate();
      const res = this.isValidFormData();
      if (!res) {
        throw new Error(`failed validate for reset password. formData: ${this.formData}`);
      }
      try {
        await this.repos.auth.resetPassword(this.formData.password ?? "", this.resetToken ?? "");
        ElMessage({
          message: 'パスワード再設定に成功しました。5秒後にログイン画面へ移動します。',
          type: "success",
        });
        setTimeout(() => {
          this.$router.push("/login");
        }, 5000);

      } catch (e) {
        console.error(e);
        ElMessage({
          message: 'パスワード再設定に失敗しました。再度パスワード再設定URLを取得してお試しください。',
          type: "error",
        });
        return;
      }
    }
  },
  mounted() :void {
    this._setResetToken();
  }
});
